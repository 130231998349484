var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"company_profile main_padding"},[_vm._m(0),_c('div',{staticClass:"map_outer w1400"},[_c('div',{staticClass:"map"},[_c('img',{staticClass:"map_main",attrs:{"src":require("@/assets/img/about/company_profile/02_map.svg"),"alt":""}}),_c('div',{staticClass:"map_btn japan",class:{active: (_vm.mapNowIndex === 0)},on:{"click":function($event){return _vm.locBoxTo(0)}}},[_c('img',{attrs:{"src":require("@/assets/img/about/company_profile/02_map_Japan.svg"),"alt":""}}),_c('div',{staticClass:"circle"})]),_c('div',{staticClass:"map_btn taipei",class:{active: (_vm.mapNowIndex === 1)},on:{"click":function($event){return _vm.locBoxTo(1)}}},[_c('img',{attrs:{"src":require("@/assets/img/about/company_profile/02_map_Taipei.svg"),"alt":""}}),_c('div',{staticClass:"circle"})]),_c('div',{staticClass:"map_btn vietnam",class:{active: (_vm.mapNowIndex === 2)},on:{"click":function($event){return _vm.locBoxTo(2)}}},[_c('img',{attrs:{"src":require("@/assets/img/about/company_profile/02_map_Vietnam.svg"),"alt":""}}),_c('div',{staticClass:"circle"})]),_c('div',{staticClass:"map_btn myanmar",class:{active: (_vm.mapNowIndex === 3)},on:{"click":function($event){return _vm.locBoxTo(3)}}},[_c('img',{attrs:{"src":require("@/assets/img/about/company_profile/02_map_Myanmar.svg"),"alt":""}}),_c('div',{staticClass:"circle"})])]),_c('swiper',{ref:"loc_box",staticClass:"loc_box",attrs:{"options":_vm.swiperOption},on:{"transitionStart":function () {
        _vm.mapNowIndex = _vm.locBox.realIndex;
      }}},[_c('swiper-slide',{staticClass:"loc_item"},[_c('figure',{staticClass:"loc_item_pic",style:({
              backgroundImage:
              ("url(" + (require('@/assets/img/about/company_profile/02_Japan.jpg')) + ")")
            })}),_c('h6',{staticClass:"txt-white"},[_vm._v(" 日本總社 ")]),_c('p',{staticClass:"txt-white"},[_vm._v(" 地址：GRC Yokohama Bay Research Park, 1-1-25, Shinurashima-cho, Kanagawa-ku, Yokohama, 221-8558, JAPAN"),_c('br'),_vm._v(" 電話：045-451-7600（代表）/ 045-451-7601（営業部）"),_c('br'),_vm._v(" 傳真：045-451-7650（代表）/ 045-451-7653（営業部） ")])]),_c('swiper-slide',{staticClass:"loc_item"},[_c('figure',{staticClass:"loc_item_pic",style:({
              backgroundImage:
              ("url(" + (require('@/assets/img/about/company_profile/02_Taipei.jpg')) + ")")
            })}),_c('h6',{staticClass:"txt-white"},[_vm._v(" 日商麒麟技術股份有限公司 台北分公司 ")]),_c('p',{staticClass:"txt-white"},[_vm._v(" 地址：105404台北市松山區復興北路57號16樓之2"),_c('br'),_vm._v(" 電子信箱：sales@kirineng.tw"),_c('br'),_vm._v(" 電話：02-2781-6270"),_c('br'),_vm._v(" 傳真：02-2773-5106 ")])]),_c('swiper-slide',{staticClass:"loc_item"},[_c('figure',{staticClass:"loc_item_pic",style:({
              backgroundImage:
              ("url(" + (require('@/assets/img/about/company_profile/02_Vietnam.jpg')) + ")")
            })}),_c('h6',{staticClass:"txt-white"},[_vm._v(" 越南支店 ")]),_c('p',{staticClass:"txt-white"},[_vm._v(" 地址：Unit No. 2A, Level 6, Saigon Centre,65 Le Loi Street, Ben Nghe Ward, District 1,Ho Chi Minh City, Vietnam"),_c('br'),_vm._v(" 電話：+84-28-3821-8600 ")])]),_c('swiper-slide',{staticClass:"loc_item"},[_c('figure',{staticClass:"loc_item_pic",style:({
              backgroundImage:
              ("url(" + (require('@/assets/img/about/company_profile/02_Myanmar.jpg')) + ")")
            })}),_c('h6',{staticClass:"txt-white"},[_vm._v(" 緬甸支店 ")]),_c('p',{staticClass:"txt-white"},[_vm._v(" 地址：Room No-401, 4th Floor, Hledan Center, (8)Quarter, Hledan, Kamaryut Township,Yangon,Myanmar"),_c('br'),_vm._v(" 電話：+95-9-899818179 ")])])],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title_outer w1400"},[_c('div',[_c('h2',{staticClass:"h1 txt-theme"},[_c('span',[_vm._v("OUR")]),_c('span',{staticClass:"txt-bold"},[_vm._v(" LOCATIONS")])]),_c('h5',{staticClass:"sub_title txt-bold txt-white"},[_vm._v("我們的交通位置")])]),_c('div',{staticClass:"controls"},[_c('div',{staticClass:"control to_left"},[_c('img',{attrs:{"src":require("@/assets/img/public/03_button/02_Button_2.svg"),"alt":""}})]),_c('div',{staticClass:"control to_right"},[_c('img',{attrs:{"src":require("@/assets/img/public/03_button/02_Button_2.svg"),"alt":""}})])])])}]

export { render, staticRenderFns }